.wallets {
  display: flex;
  flex-direction: column;
  max-width: 25%;
}

.walletBody {
  display: flex;
  flex-direction: row;
}
